<template>
  <div class="row">
    <div class="col-9">
      <v-tasks-list/>
    </div>

    <div class="col-3">
      <v-birthdays-list/>
    </div>

  </div>

</template>

<script>

import VTasksList from "@/components/v-tasks-list";
import VBirthdaysList from "@/components/v-birthdays-list.vue";

export default {
  name: "v-main-page",
  components: {VBirthdaysList, VTasksList}
}
</script>


<style>

</style>