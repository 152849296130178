import { createStore } from 'vuex'
import axios from 'axios'

let store = createStore({
    state: {
        children: [],
        parents: [],
        employees: [],
        tasks: [],
        leads: [],
    },
    mutations: {
        SET_CHILDREN_TO_STATE: (state, children) => {
            state.children = children;
        },
        SET_PARENTS_TO_STATE: (state, parents) => {
            state.parents = parents;
        },
        SET_EMPLOYEES_TO_STATE: (state, employees) => {
            state.employees = employees;
        },
        SET_TASKS_TO_STATE: (state, tasks) => {
            state.tasks = tasks;
        },
        SET_LEADS_TO_STATE: (state, leads) => {
            state.leads = leads;
        },
    },
    actions: {
        GET_CHILDREN_FROM_API({commit}) {
            return axios('/child/', {
                method: "GET"
            })
                .then((children) => {
                     commit('SET_CHILDREN_TO_STATE', children.data)
                })
        },
        GET_PARENTS_FROM_API({commit}) {
            return axios('/parent/', {
                method: "GET"
            })
                .then((parents) => {
                    commit('SET_PARENTS_TO_STATE', parents.data)
                })
        },
        GET_EMPLOYEES_FROM_API({commit}) {
            return axios('/employee/', {
                method: "GET"
            })
                .then((employees) => {
                    commit('SET_EMPLOYEES_TO_STATE', employees.data)
                })
        },
        GET_TASKS_FROM_API({commit}) {
            return axios('/task/', {
                method: "GET"
            })
                .then((task) => {
                    commit('SET_TASKS_TO_STATE', task.data)
                })
        },
        GET_LEADS_FROM_API({commit}) {
            return axios('/lead/', {
                method: "GET"
            })
                .then((leads) => {
                    commit('SET_LEADS_TO_STATE', leads.data)
                })
        },
    },
    getters: {
        CHILDREN(state) {
            return state.children;
        },
        PARENTS(state) {
            return state.parents;
        },
        EMPLOYEES(state) {
            return state.employees;
        },
        TASKS(state) {
            return state.tasks;
        },
        LEADS(state) {
            return state.leads;
        },
    },
});

export default store;