<template>
  <div class='v-birthdays-list'>
    <h3>Дни рождения:</h3>
    <div v-if="CHILDREN.length > 0">
      <h4>Дети</h4>
      <p v-for="child in CHILDREN"
      :key="child.id">
        {{child.lastName}} {{child.firstName}} {{child.dateOfBirth}}
      </p>
    </div>
    <div v-if="EMPLOYEES.length > 0">
      <h4>Сотрудники</h4>
      <p v-for="employee in EMPLOYEES"
         :key="employee.id">
        {{employee.lastName}} {{employee.firstName}} {{employee.dateOfBirth}}
      </p>
    </div>
    <div v-if="PARENTS.length > 0">
      <h4>Родители</h4>
      <p v-for="parent in PARENTS"
         :key="parent.id">
        {{parent.lastName}} {{parent.firstName}} {{parent.dateOfBirth}}
      </p>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import moment from "moment/moment";

export default {
  name: "v-birthdays-list",
  props: {},
  data() {
    return {
      CHILDREN: [],
      EMPLOYEES: [],
      PARENTS: [],
    }
  },
  methods: {
    async GET_CHILD_BIRTHDAYS() {
      const response = await axios.get('/child/')
      this.CHILDREN = [];
      response.data.forEach(element => {
        let date = moment(element.dateOfBirth, "DD.MM.YYYY");
        let two_weeks = moment(Date()).year(date.year()).add(14, 'days').toDate();
        if (date.isBefore(two_weeks) && date.isAfter(moment(Date()).year(date.year()))) {
          this.CHILDREN.push(element)
        }
      });
    },
    async GET_EMPLOYEE_BIRTHDAYS() {
      const response = await axios.get('/employee/')
      this.EMPLOYEES = [];
      response.data.forEach(element => {
        let date = moment(element.dateOfBirth, "DD.MM.YYYY");
        let two_weeks = moment(Date()).year(date.year()).add(14, 'days').toDate();
        if (date.isBefore(two_weeks) && date.isAfter(moment(Date()).year(date.year()))) {
          this.EMPLOYEES.push(element)
        }
      });
    },
    async GET_PARENTS_BIRTHDAYS() {
      const response = await axios.get('/parent/')
      this.PARENTS = [];
      response.data.forEach(element => {
        let date = moment(element.dateOfBirth, "DD.MM.YYYY");
        let two_weeks = moment(Date()).year(date.year()).add(14, 'days').toDate();
        if (date.isBefore(two_weeks) && date.isAfter(moment(Date()).year(date.year()))) {
          this.PARENTS.push(element)
        }
      });
    },
  },
  mounted() {
    this.GET_CHILD_BIRTHDAYS();
    this.GET_EMPLOYEE_BIRTHDAYS();
    this.GET_PARENTS_BIRTHDAYS();
  },

}

</script>

<style>

</style>