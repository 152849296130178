<template>
  <div>
    <h1>LOGIN</h1>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="login">Логин</label>
        <div class="form-group col-3">
          <input v-model="login" id="login" class="form-control" placeholder="login" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="password">Пароль</label>
        <div class="form-group col-3">
          <input v-model="password" id="password" class="form-control" placeholder="password" type="password" @keyup.enter="this.do_login"/>
        </div>
      </div>
      <button class="btn btn-primary" type="submit" @click="this.do_login">Login</button>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router/router";

export default {
  name: "v-login",
  data: () => {
    return {
      login: "",
      password: "",
    };
  },
  methods: {
    do_login() {
        axios.post('/login', {
          login: this.login,
          password: this.password,
        }).then(response => {
          axios.defaults.headers.common['Authorization'] = response.headers['authorization'];
          localStorage.setItem('user', JSON.stringify({
            'login': this.login,
            'token': response.headers['authorization']
          }));
          router.push("/")
        })


    },
  },
}
</script>

<style scoped>

</style>