<template>
    <div class='v-employees-list'>
      <h1>Сотрудники</h1>
      <div class="form-check form-control-lg">
        <input class="form-check-input" type="checkbox" v-model="showArchived" id="showArchived">
        <label class="form-check-label" for="showArchived">
          Показать архивные
        </label>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>id</th>
            <th>ФИО</th>
            <th>Телефон</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="employee in FILTERED_EMPLOYEES"
              :key="employee.id">
            <th scope="row"><router-link :to="{name: 'employee', params: {id: employee.id}}">{{ employee.id }}</router-link></th>
            <td>{{ employee.lastName }} {{ employee.firstName }}</td>
            <td>{{ employee.phone }}<a :href="'https://wa.me/'+helpers.clean_phone(employee.phone)" target="_blank"><img src="@/assets/whatsapp_logo.png" width="24" height="24"></a></td>
        </tr>
        </tbody>
      </table>
      <router-link :to="{name: 'employee'}"><button type="button" class="btn btn-primary">Добавить</button></router-link>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import helpers from "@/service/helpers";


    export default {
        name: "v-employees-list",
        props: {},
        data() {
          return {
            showArchived: false,
          }
        },
        computed: {
          helpers() {
            return helpers
          },
          FILTERED_EMPLOYEES: function () {
            let DISPLAY_EMPLOYEES
            if (this.showArchived) {
              DISPLAY_EMPLOYEES = this.EMPLOYEES
            } else {
              DISPLAY_EMPLOYEES = this.EMPLOYEES.filter(employee => !employee.archived)
            }
            return DISPLAY_EMPLOYEES.sort(function(a, b) {
              if (a.lastName.toLocaleLowerCase() < b.lastName.toLocaleLowerCase()) return -1;
              if (a.lastName.toLocaleLowerCase() > b.lastName.toLocaleLowerCase()) return 1;
              return 0;
            });
          },
          ...mapGetters(['EMPLOYEES']),
        },
        methods: {
          ...mapActions(['GET_EMPLOYEES_FROM_API']),
        },
        mounted() {
          this.GET_EMPLOYEES_FROM_API();
        }
    }

</script>

<style>

</style>