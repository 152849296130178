<template>
  <div class='v-parents-list'>
    <h3>Родители для {{ child.lastName }} {{ child.firsName }}</h3>
    <table class="table">
      <thead>
      <tr>
        <th> </th>
        <th>ФИО</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="parent in parents"
          :key="parent.id"
      >
        <th scope="row"><button class="btn btn-primary" @click="addParent(parent.id)">Выбрать</button></th>
        <td>{{ parent.lastName }} {{ parent.firstName }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router/router";

export default {
  name: "v-child-add-parent",
  data() {
    return {
      child: {
        id: 0,
        firsName: "",
        lastName: "",
      },
      parents: {
        id: 0,
        firstName: "",
        lastName: "",
      }
    }
  },
  methods: {
    async GET_CHILD(id) {
      const response = await axios.get('/child/' + id)
      this.child = response.data;
    },
    async GET_PARENTS_FROM_API() {
      const response = await axios.get('/parent/')
      this.parents = response.data;
    },
    addParent(parent_id) {
      axios.post('/child/'+this.child.id + '/addParent/'+parent_id, "")
      router.push("/child/"+this.child.id)
    },
  },
  mounted() {
    let child_id = this.$route.params.id
    this.GET_CHILD(child_id);
    this.GET_PARENTS_FROM_API();
  }
}
</script>

<style scoped>

</style>