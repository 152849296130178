<template>
  <div class='v-settings'>
    <div>
      <h3>Сменить пароль</h3>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="currentPassword">Текущий пароль</label>
        <div class="form-group col-8">
          <input type="text" class="form-control" id="currentPassword" v-model="currentPassword">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="newPassword">Новый пароль</label>
        <div class="form-group col-8">
          <input type="text" class="form-control" id="newPassword" v-model="newPassword">
        </div>
      </div>
      <button type="button" class="btn btn-primary" @click="CHANGE_PASSWORD">Сохранить</button>
      <div v-if="this.changeError" class="alert alert-danger" role="alert">
        Ошибка смены пароля. {{ this.errorMessage }}
      </div>
      <div v-if="this.changeOk" class="alert alert-success" role="alert">
        Пароль успешно сменён.
      </div>
    </div>
    <div>
      <h3>Статьи доходов и рассходов.</h3>
      <p v-for="category in TRANSACTION_CATEGORIES" :key="category.id">
        <b>{{category.name}}</b> {{transactionTypes[category.transactionType]}}
        <button type="button" class="btn btn-outline-success" @click="transaction_category=category">ред</button>
      </p>
      <div>
        <div class="form-group row">
          <label class="col-form-label col-3 form-control-lg" for="name">Название</label>
          <div class="form-group col-3">
            <input type="text" class="form-control" id="name" v-model="transaction_category.name">
          </div>
          <div class="form-group col-3">
            <select class="form-select" aria-label="Default select example" v-model="transaction_category.transactionType">
              <option selected value="">Выберите тип:</option>
              <option value="DEBIT">Приход</option>
              <option value="CREDIT">Расход</option>
              <option value="SALARY">Зар.плата</option>
              <option value="PAYMENT">Оплата</option>
              <option value="CORRECT">Корректировка</option>
            </select>
          </div>
        </div>
        <button type="button" class="btn btn-primary" @click="save_transaction_category">Сохранить</button>
      </div>
    </div>
    <div>
      <h3>Счета</h3>
      <p v-for="wallet in WALLETS" :key="wallet.id">
        <b>{{wallet.name}} - {{wallet.amount / 100 }}</b> {{wallet.note}}
        <button type="button" class="btn btn-outline-success" @click="editable_wallet=wallet">ред</button>
        <button type="button" class="btn btn-outline-danger" @click="delete_wallet(wallet.id)">удалить</button>
      </p>
      <div>
        <div class="form-group row">
          <label class="col-form-label col-2 form-control-lg" for="name">Название</label>
          <div class="form-group col-3">
            <input type="text" class="form-control" id="name" v-model="editable_wallet.name">
          </div>
          <label class="col-form-label col-2 form-control-lg" for="name">Примечание</label>
          <div class="form-group col-3">
            <input type="text" class="form-control" id="name" v-model="editable_wallet.note">
          </div>
        </div>
        <button type="button" class="btn btn-primary" @click="save_wallet">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "v-settings",
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      changeOk: false,
      changeError: false,
      errorMessage: "",
      TRANSACTION_CATEGORIES: [],
      transaction_category:{ name: "", transactionType: ""},
      transactionTypes:{
        DEBIT: "Приход",
        CREDIT: "Расход",
        SALARY: "Зар.плата",
        PAYMENT: "Оплата",
        CORRECT: "Корректировка",
      },
      WALLETS: [],
      editable_wallet: {
        name: "",
        note: ""
      }
    }
  },
  methods: {
    async CHANGE_PASSWORD() {
      let changePassword = {"currentPassword": this.currentPassword, "newPassword": this.newPassword}
      const response = await axios.post('/user/changePassword', changePassword)
      if (response.data.toString().indexOf("mismatch") > 0) {
        this.errorMessage = "Старый пароль неверный."
        this.changeOk = false;
        this.changeError = true;
      } else {
        this.changeOk = true;
        this.changeError = false;
      }
    },
    async get_transaction_categories() {
      let response = await axios.get('/transaction_category/')
      this.TRANSACTION_CATEGORIES = response.data
    },
    async get_wallets() {
      let response = await axios.get('/wallet/')
      this.WALLETS = response.data
    },
    async save_transaction_category() {
      await axios.post('/transaction_category/', this.transaction_category)
      delete this.transaction_category.id
      this.transaction_category.name=""
      this.transaction_category.transactionType=""
      await this.get_transaction_categories();
    },
    async save_wallet() {
      await axios.post('/wallet/', this.editable_wallet)
      delete this.editable_wallet.id
      this.editable_wallet.name=""
      this.editable_wallet.note=""
      await this.get_wallets();
    },
    async delete_wallet(id) {
        if (window.confirm("Удалить счёт?")) {
            await axios.delete('/wallet/'+id)
            await this.get_wallets();
        }
    },
  },
  mounted() {
    this.get_transaction_categories();
    this.get_wallets();
  },
}
</script>

<style scoped>

</style>