<template>
    <div class='v-task-details'>
      <div class="form-group row">
        <label for="title" class="col-2 col-form-label"><h3>Задача</h3></label>
        <div class="col-9">
          <input type="text" class="form-control form-control-lg" id="title" placeholder="Название задачи" v-model="task.title">
        </div>
        <div class="col-1">
          <font-awesome-icon class="form-control-lg" v-if="task.id && task.done" icon="fa-solid fa-circle-check" color="GREEN"/>
          <font-awesome-icon class="form-control-lg" v-if="task.id && !task.done" icon="fa-solid fa-xmark" color="RED"/>
        </div>
      </div>
      <div class="row">
        <label for="description"><h5>Описание задачи</h5></label>
        <textarea v-model="task.description" class="form-control" id="description" rows="10"></textarea>
      </div>
          <div class="form-group row">
            <label for="title" class="col-sm-3 col-form-label"><h5>Планируемая дата</h5></label>
            <div class="col-sm-9">
            <Datepicker
                :enable-time-picker="true"
                format='dd.MM.yyyy HH:mm'
                id="dateOfBirth"
                @update:model-value="DATE_SELECTED"
                v-model="date"/>
            </div>
            <h5><p v-if="task.id"> Дата создания: {{ task.createTime}}</p></h5>
            <h5><p v-if="task.done"> Выполнена: {{ task.completionTime }}</p></h5>
          </div>
      <button type="button" class="btn btn-primary m-3" @click="UPDATE_TASK">Сохранить</button>
      <button v-if="task.id && !task.done" type="button" class="btn btn-primary m-3" @click="DONE_TASK">Отметить выполненым</button>
      <button v-if="task.id && task.done" type="button" class="btn btn-primary m-3" @click="DONE_TASK">Отметить не выполненым</button>

      </div>
</template>

<script>
import axios from 'axios'
import router from "@/router/router";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment";

export default {
        name: "v-task-details",
        props: {
        },
        data() {
          return {
            date: moment().toDate(),
            task: {
              "title": "",
              "description": "",
              "createTime": "01.01.2000_00:00",
              "scheduleTime": "31.12.2023_12:00",
              "completionTime": "01.01.2100_00:00",
              "done": false,
            }
          }
        },
      components: {
        Datepicker,
      },
        computed: {
        },
      methods: {
        async GET_TASK(id) {
          const response = await axios.get('/task/'+id)
          this.task = response.data;
          this.date = moment(this.task.scheduleTime, "DD.MM.YYYY_HH:mm").toDate();
        },
        async UPDATE_TASK() {
          const response = await axios.post('/task/', this.task)
          this.task = response.data;
          await router.push("/")
        },
        async DONE_TASK() {
          let action = this.task.done?"/undone/":"/done/"
          await axios.post('/task/' + this.task.id + action, "")
          await router.push("/")
        },
        DATE_SELECTED() {
          this.$nextTick(() => {
            this.task.scheduleTime = moment(this.date).format("DD.MM.YYYY_HH:mm");
          })
        }
      },
      mounted() {
          let id = this.$route.params.id
          if ( id ) {
            this.GET_TASK(this.$route.params.id)
          }
      }
    }

</script>

<style>
    .v-task-details{

    }
</style>