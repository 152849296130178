<template>
    <div class='v-leads-list'>
      <h1>Лиды</h1>
      <div class="form-check form-control-lg">
        <input class="form-check-input" type="checkbox" v-model="showArchived" id="showArchived">
        <label class="form-check-label" for="showArchived">
          Показать архивные
        </label>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>id</th>
            <th>ФИО</th>
            <th>Телефон</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="lead in FILTERED_LEADS"
              :key="lead.id">
            <th scope="row"><router-link :to="{name: 'lead', params: {id: lead.id}}">{{ lead.id }}</router-link></th>
            <td>{{ lead.lastName }} {{ lead.firstName }}</td>
            <td>{{ lead.phone }}<a :href="'https://wa.me/'+helpers.clean_phone(lead.phone)" target="_blank"><img src="@/assets/whatsapp_logo.png" width="24" height="24"></a></td>
          </tr>
        </tbody>
      </table>
      <router-link :to="{name: 'lead'}"><button type="button" class="btn btn-primary">Добавить</button></router-link>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import helpers from "@/service/helpers";


export default {
        name: "v-leads-list",
        props: {},
        data() {
          return {
            showArchived: false,
          }
        },
        computed: {
          helpers() {
            return helpers
          },
          FILTERED_LEADS: function () {
            if (this.showArchived) {
              return this.LEADS
            } else {
              return this.LEADS.filter(lead => !lead.archived)
            }
          },
          ...mapGetters(['LEADS']),
        },
        methods: {
          ...mapActions(['GET_LEADS_FROM_API']),
        },
        mounted() {
          this.GET_LEADS_FROM_API();
        }
    }

</script>

<style>

</style>