<template>
    <div class='v-parents-list'>
      <h1>Родители</h1>
      <div class="form-check form-control-lg">
        <input class="form-check-input" type="checkbox" v-model="showArchived" id="showArchived">
        <label class="form-check-label" for="showArchived">
          Показать архивные
        </label>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>id</th>
            <th>ФИО</th>
            <th>Телефон</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="parent in FILTERED_PARENTS"
              :key="parent.id">
            <th scope="row"><router-link :to="{name: 'parent', params: {id: parent.id}}">{{ parent.id }}</router-link></th>
            <td>{{ parent.lastName }} {{ parent.firstName }}</td>
            <td>{{ parent.phone }}<a :href="'https://wa.me/'+helpers.clean_phone(parent.phone)" target="_blank"><img src="@/assets/whatsapp_logo.png" width="24" height="24"></a></td>
          </tr>
        </tbody>
      </table>
      <router-link :to="{name: 'parent'}"><button type="button" class="btn btn-primary">Добавить</button></router-link>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import helpers from '../service/helpers'


export default {
        name: "v-parents-list",
        props: {},
        data() {
          return {
            showArchived: false,
          }
        },
        computed: {
          helpers() {
            return helpers
          },
          FILTERED_PARENTS: function () {
            let DISPLAY_PARENTS
            if (this.showArchived) {
              DISPLAY_PARENTS = this.PARENTS
            } else {
              DISPLAY_PARENTS = this.PARENTS.filter(parent => !parent.archived)
            }
            return DISPLAY_PARENTS.sort(function(a, b) {
              if (a.lastName.toLocaleLowerCase() < b.lastName.toLocaleLowerCase()) return -1;
              if (a.lastName.toLocaleLowerCase() > b.lastName.toLocaleLowerCase()) return 1;
              return 0;
            });
          },
          ...mapGetters(['PARENTS']),
        },
        methods: {
          ...mapActions(['GET_PARENTS_FROM_API']),
        },
        mounted() {
          this.GET_PARENTS_FROM_API();
        }
    }

</script>

<style>

</style>