<template>
  <div class='v-employees-list row'>
    <div class="col-12">
      <h1>Финансовые операции</h1>
        <h3>Счета:</h3>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        <div v-for="wallet in WALLETS"
             :key="wallet.id"
             class="col"
        >
          <div class="card">
            <h4 class="card-header">
              {{ wallet.name }}:{{ wallet.amount / 100 }}
            </h4>
          </div>
        </div>
      </div>
      <router-link :to="{name: 'transaction'}">
        <button type="button" class="btn btn-primary">Добавить</button>
      </router-link>
      <table class="table">
        <thead>
        <tr>
          <th>Дата</th>
          <th>Счёт</th>
          <th>Сумма</th>
          <th>Тип операции</th>
          <th>Категория</th>
          <th>Доп. информация</th>
          <th>ред</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="transaction in TRANSACTIONS"
            :key="transaction.id">
          <th scope="row">{{ transaction.dateTime }}</th>
          <td>{{ transaction.wallet.name }}</td>
          <td>{{ transaction.amount / 100 }}</td>
          <td>{{ transactionTypes[transaction.transactionType] }}</td>
          <td>{{ transaction.transactionCategory.name }}</td>
          <td>
            <div v-if="transaction.transactionType === 'SALARY'">
              {{ this.EMPLOYEES[transaction.arguments.employee].lastName }}
              {{ this.EMPLOYEES[transaction.arguments.employee].firstName }}

            </div>
            <div v-if="transaction.transactionType === 'PAYMENT'">
              {{ this.CHILDREN[transaction.arguments.child].lastName }}
              {{ this.CHILDREN[transaction.arguments.child].firstName }}

            </div>
          </td>
          <td>
            <router-link :to="{name: 'transaction', params: {id: transaction.id}}">
              <button type="button" class="btn btn-outline-success">ред</button>
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>


import axios from "axios";

export default {
  name: "v-transactions-list",
  props: {},
  data() {
    return {
      TRANSACTIONS: [],
      EMPLOYEES: [],
      CHILDREN: [],
      WALLETS: [],
      transactionTypes: {
        DEBIT: "Приход",
        CREDIT: "Расход",
        SALARY: "Зар.плата",
        PAYMENT: "Оплата",
        CORRECT: "Корректировка",
      },
    }
  },
  computed: {},
  methods: {
    async GET_WALLETS() {
      let response = await axios.get('/wallet/')
      this.WALLETS = response.data
    },
    async GET_TRANSACTIONS() {
      let response = await axios.get('/transaction/')
      this.TRANSACTIONS = response.data
    },
    async GET_EMPLOYEES() {
      const response = await axios.get('/employee/')
      response.data.forEach(element => {
        this.EMPLOYEES[element.id] = element
      });
    },
    async GET_CHILDREN() {
      const response = await axios.get('/child/')
      response.data.forEach(element => {
        this.CHILDREN[element.id] = element
      });
    },
  },
  mounted() {
    this.GET_WALLETS();
    this.GET_EMPLOYEES();
    this.GET_CHILDREN();
    this.GET_TRANSACTIONS();
  }
}

</script>

<style>

</style>