<template>
    <div class='v-employees-list'>
      <div>
        <h1>Задачи</h1>
        <div class="form-check form-control-lg">
          <input class="form-check-input" type="checkbox" v-model="showClosed" id="showClosedTasks">
          <label class="form-check-label" for="showClosedTasks">
            Показать закрытые
          </label>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
          <div v-for="task in tasks"
               :key="task.id"
               class="col"
          >
            <div class="card">
              <h4 class="card-header">
                <font-awesome-icon v-if="task.done" icon="fa-solid fa-circle-check" color="GREEN"/>
                <font-awesome-icon v-else icon="fa-solid fa-xmark" color="RED"/>
                {{ task.title }}
              </h4>
              <div class="card-body">
                <h5 class="card-text">{{ task.description }}</h5>
                <p class="card-text">Создана: {{ task.createTime }}</p>
                <p class="card-text">Запланирована на: {{ task.scheduleTime }}</p>
                <p v-if="task.done" class="card-text">Выполнена: {{ task.completionTime  }}</p>
                <router-link :to="{name: 'task', params: {id: task.id}}" class="btn btn-primary">Подробно</router-link>
              </div>
            </div>
          </div>
        </div>
        <router-link :to="{name: 'task'}"><button type="button" class="btn btn-success m-3">Добавить</button></router-link>
      </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: "v-tasks-list",
        props: {},
        data() {
            return {
              showClosed: false,
            }
        },
        computed: {
          ...mapGetters(['TASKS']),
          tasks: function () {
            if (this.showClosed) {
              return this.TASKS
            } else {
              return this.TASKS.filter(task => !task.done)
            }
          }
        },
        methods: {
          ...mapActions(['GET_TASKS_FROM_API']),
        },
        mounted() {
          this.GET_TASKS_FROM_API();
        }
    }

</script>

<style>

</style>