<template>
  <div class='v-lead-details'>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="firstName">Имя</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="firstName" placeholder="Имя" v-model="lead.firstName">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="lastName">Фамилия</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="lead.lastName">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="fatherName">Отчество</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="fatherName" placeholder="Отчество" v-model="lead.fatherName">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="dateOfBirth">Дата рождения</label>
      <div class="form-group col-8">
        <Datepicker
            :enable-time-picker="false"
            format='dd.MM.yyyy'
            id="dateOfBirth"
            @update:model-value="DATE_SELECTED"
            v-model="date"
            class="form-control"/>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="passport">Паспорт</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="passport" placeholder="Паспорт" v-model="lead.passport">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="phone">Телефон</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="phone" placeholder="Телефон" v-model="lead.phone">
      </div>
      <div class="col-1"><a :href="'https://wa.me/'+helpers.clean_phone(lead.phone)" target="_blank"><img src="@/assets/whatsapp_logo.png" width="24" height="24"></a></div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="email">E-mail</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="email" placeholder="email" v-model="lead.email">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="description">Примечание</label>
      <div class="form-group col-8">
        <textarea v-model="lead.note" class="form-control" id="description" rows="3"></textarea>
      </div>
    </div>
    <div class="form-check form-control-lg">
      <input class="form-check-input" type="checkbox" v-model="lead.archived" id="archived">
      <label class="form-check-label" for="archived">
        В архиве
      </label>
    </div>
    <button type="button" class="btn btn-primary" @click="UPDATE_LEAD">Сохранить</button>
    <button v-if="lead.id" type="button" class="btn btn-primary" @click="CONVERT_TO_PARENT">Сделать клиентом</button>
  </div>
</template>

<script>
import axios from 'axios'
import router from "@/router/router";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment";
import helpers from "@/service/helpers";

export default {
  name: "v-lead-details",
  props: {},
  data() {
    return {
      date: moment("01.01.2000", "DD.MM.YYYY").toDate(),
      lead: {
        firstName: "",
        lastName: "",
        fatherName: "",
        dateOfBirth: "01.01.2000",
        passport: "",
        phone: "",
        email: "",
        note: "",
        archived: false,
      }
    }
  },
  components: {
    Datepicker,
  },
  computed: {
    helpers() {
      return helpers
    }
  },
  methods: {
    async GET_LEAD(id) {
      const response = await axios.get('/lead/' + id)
      this.lead = response.data;
      this.date = moment(this.lead.dateOfBirth, "DD.MM.YYYY").toDate();
    },
    async UPDATE_LEAD() {
      const response = await axios.post('/lead/', this.lead)
      this.lead = response.data;
      await router.push("/leads")
    },
    async CONVERT_TO_PARENT() {
      const response = await axios.post('/lead/' + this.lead.id + '/convert')
      let parent = response.data;
      await router.push("/parent/" + parent.id)
    },
    DATE_SELECTED() {
      this.$nextTick(() => {
        this.lead.dateOfBirth = moment(this.date).format("DD.MM.YYYY");
      })
    }
  },
  mounted() {
    let id = this.$route.params.id
    if (id) {
      this.GET_LEAD(this.$route.params.id)
    }
  }
}

</script>

<style>
</style>