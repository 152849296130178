<template>
  <div class='v-report-payment row'>
    <div class="col-12">
      <h1>Оплаты</h1>
      <table class="table">
        <thead>
        <tr>
          <th>Ребёнок</th>
          <th v-for="period in this.report.periods"
          :key="period">
            {{ period }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="child_id in this.report.children_ids"
            :key="child_id">

          <th scope="row">
            {{ this.CHILDREN[child_id].lastName }}
            {{ this.CHILDREN[child_id].firstName }}</th>
          <td v-for="period in this.report.periods"
              :key="period">
            <p v-if="this.report.amounts[child_id] && this.report.amounts[child_id][period]">
            {{ this.report.amounts[child_id][period] / 100 }}
            </p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>


import axios from "axios";

export default {
  name: "v-report-payment",
  props: {},
  data() {
    return {
      TRANSACTIONS: {},
      CHILDREN: [],
      report: {
        children_ids: [],
        periods: [],
        amounts: [],
      }
    }
  },
  computed: {},
  methods: {
    async GET_TRANSACTIONS() {
      let response = await axios.get('/transaction/report/payment')
      this.report = await response.data

    },
    async GET_CHILDREN() {
      const response = await axios.get('/child/')
      response.data.forEach(element => {
        this.CHILDREN[element.id] = element
      });
    },
  },
  mounted() {
    this.GET_CHILDREN();
    this.GET_TRANSACTIONS();
  }
}

</script>

<style>

</style>