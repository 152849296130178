import { createApp } from 'vue'
import App from './App.vue'
import store from './vuex/store'
import router from './router/router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import axios from "axios";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {faCircleCheck, faXmark} from '@fortawesome/free-solid-svg-icons'
library.add(faXmark, faCircleCheck)

const app = createApp(App)

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

let loggedIn = localStorage.getItem('user');
if (loggedIn) {
    let auth = JSON.parse(localStorage.getItem('user'));
    axios.defaults.headers.common['Authorization'] = auth['token'];
}

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 403) {
        localStorage.removeItem("user");
        router.push("/login").then();
    } else {
        return Promise.reject(error);
    }

});


app.use(store)
app.use(router)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')