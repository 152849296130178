import { createRouter, createWebHashHistory } from 'vue-router'

import vChildrenList from '../components/v-children-list'
import vParentsList from '../components/v-parents-list'
import vEmployeesList from '../components/v-employees-list'
import vChildDetails from '../components/v-child-details'
import vParentDetails from '../components/v-parent-details'
import vEmployeeDetails from '../components/v-employee-details'
import vChildAddParent from '../components/v-child-add-parent'
import vTasksList from '../components/v-tasks-list'
import vTaskDetails from '../components/v-task-details'
import vLogin from '../components/v-login'
import vSettings from '../components/v-settings'
import vLeadsList from '../components/v-leads-list'
import vLeadDetails from '../components/v-lead-details'
import vTransactionsList from '../components/v-transactions-list'
import vTransactionDetails from '../components/v-transaction-details'
import vReportPayment from '../components/v-report-payment'
import vReportSalary from '../components/v-report-salary'
import vMainPage from '../components/v-main-page'

const routes = [
    {
        path: '/',
        name: 'main_page',
        component: vMainPage
    },
    {
        path: '/tasks',
        name: 'tasks_list',
        component: vTasksList
    },
    {
        path: '/task/:id?',
        name: 'task',
        component: vTaskDetails
    },
    {
        path: '/children',
        name: 'children_list',
        component: vChildrenList
    },
    {
        path: '/parents',
        name: 'parents_list',
        component: vParentsList
    },
    {
        path: '/employees',
        name: 'employees_list',
        component: vEmployeesList
    },
    {
        path: '/child/:id?',
        name: 'child',
        component: vChildDetails,
        props: true,
    },
    {
        path: '/parent/:id?',
        name: 'parent',
        component: vParentDetails,
        props: true,
    },
    {
        path: '/employee/:id?',
        name: 'employee',
        component: vEmployeeDetails,
        props: true,
    },
    {
        path: '/child/:id/addParent',
        name: 'addParentToChild',
        component: vChildAddParent,
        props: true,
    },
    {
        path: "/login",
        name: "login",
        component: vLogin,
    },
    {
        path: "/settings",
        name: "settings",
        component: vSettings,
    },
    {
        path: '/leads',
        name: 'leads_list',
        component: vLeadsList
    },
    {
        path: '/lead/:id?',
        name: 'lead',
        component: vLeadDetails,
        props: true,
    },
    {
        path: '/transactions',
        name: 'transactions_list',
        component: vTransactionsList
    },
    {
        path: '/transaction/:id?',
        name: 'transaction',
        component: vTransactionDetails,
        props: true,
    },
    {
        path: '/report/payment',
        name: 'report_payment',
        component: vReportPayment
    },
    {
        path: '/report/salary',
        name: 'report_salary',
        component: vReportSalary
    },
]

const router = createRouter( {
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;