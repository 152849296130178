<template>
  <div class='v-parent-details'>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="firstName">Имя</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="firstName" placeholder="Имя" v-model="parent.firstName">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="lastName">Фамилия</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="parent.lastName">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="fatherName">Отчество</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="fatherName" placeholder="Отчество" v-model="parent.fatherName">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="dateOfBirth">Дата рождения</label>
      <div class="form-group col-8">
        <Datepicker
            :enable-time-picker="false"
            format='dd.MM.yyyy'
            id="dateOfBirth"
            @update:model-value="DATE_SELECTED"
            v-model="date"
            class="form-control"/>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="passport">Паспорт</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="passport" placeholder="Паспорт" v-model="parent.passport">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="phone">Телефон</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="phone" placeholder="Телефон" v-model="parent.phone">
      </div>
      <div class="col-1"><a :href="'https://wa.me/'+helpers.clean_phone(parent.phone)" target="_blank"><img src="@/assets/whatsapp_logo.png" width="24" height="24"></a></div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="email">E-mail</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="email" placeholder="email" v-model="parent.email">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="description">Примечание</label>
      <div class="form-group col-8">
        <textarea v-model="parent.note" class="form-control" id="description" rows="3"></textarea>
      </div>
    </div>
    <div class="form-check form-control-lg">
      <input class="form-check-input" type="checkbox" v-model="parent.archived" id="archived">
      <label class="form-check-label" for="archived">
        В архиве
      </label>
    </div>
    <button type="button" class="btn btn-primary" @click="UPDATE_PARENT">Сохранить</button>
    <div v-if="parent.children && parent.children.length > 0">
      <h3>Дети</h3>
      <table class="table">
        <thead>
        <tr>
          <th>id</th>
          <th>ФИО</th>
          <th>Дата рождения</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="child in parent.children"
            :key="child.id">
          <th scope="row">
            <router-link :to="{name: 'child', params: {id: child.id}}">{{ child.id }}</router-link>
          </th>
          <th>{{ child.lastName }} {{ child.firstName }}</th>
          <th>{{ child.dateOfBirth }}</th>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import router from "@/router/router";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment";
import helpers from "@/service/helpers";

export default {
  name: "v-parent-details",
  props: {},
  data() {
    return {
      date: moment("01.01.2000", "DD.MM.YYYY").toDate(),
      parent: {
        firstName: "",
        lastName: "",
        fatherName: "",
        dateOfBirth: "01.01.2000",
        passport: "",
        phone: "",
        email: "",
        note: "",
        archived: false,
      }
    }
  },
  components: {
    Datepicker,
  },
  computed: {
    helpers() {
      return helpers
    }
  },
  methods: {
    async GET_PARENT(id) {
      const response = await axios.get('/parent/' + id)
      this.parent = response.data;
      this.date = moment(this.parent.dateOfBirth, "DD.MM.YYYY").toDate();
    },
    async UPDATE_PARENT() {
      const response = await axios.post('/parent/', this.parent)
      this.parent = response.data;
      router.push("/parents")
    },
    DATE_SELECTED() {
      this.$nextTick(() => {
        this.parent.dateOfBirth = moment(this.date).format("DD.MM.YYYY");
      })
    }
  },
  mounted() {
    let id = this.$route.params.id
    if (id) {
      this.GET_PARENT(this.$route.params.id)
    }
  }
}

</script>

<style>
.v-parent-details {

}
</style>