const months = [
    {index: "01", title: "Январь"},
    {index: "02", title: "Февраль"},
    {index: "03", title: "Март"},
    {index: "04", title: "Апрель"},
    {index: "05", title: "Май"},
    {index: "06", title: "Июнь"},
    {index: "07", title: "Июль"},
    {index: "08", title: "Август"},
    {index: "09", title: "Сентябрь"},
    {index: "10", title: "Октябрь"},
    {index: "11", title: "Ноябрь"},
    {index: "12", title: "Декабрь"},
]

export default {
    months,
}