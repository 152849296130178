<template>
    <div class='v-children-list'>
      <h1>Дети</h1>
      <div class="form-check form-control-lg">
        <input class="form-check-input" type="checkbox" v-model="showArchived" id="showArchived">
        <label class="form-check-label" for="showArchived">
          Показать архивные
        </label>
      </div>
        <table class="table">
          <thead>
            <tr>
              <th>id</th>
              <th>ФИО</th>
              <th>Дата рождения</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="child in FILTERED_CHILDREN"
                :key="child.id">
                <th scope="row"><router-link :to="{name: 'child', params: {id: child.id}}">{{ child.id }}</router-link></th>
                <td>{{ child.lastName }} {{ child.firstName }}</td>
                <td>{{ child.dateOfBirth }}</td>
            </tr>
          </tbody>
        </table>

      <router-link :to="{name: 'child'}"><button type="button" class="btn btn-primary">Добавить</button></router-link>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'


    export default {
        name: "v-children-list",
        props: {},
        data() {
          return {
            showArchived: false,
          }
        },
        computed: {
          FILTERED_CHILDREN: function () {
            let DISPLAY_CHILDREN
            if (this.showArchived) {
              DISPLAY_CHILDREN = this.CHILDREN
            } else {
              DISPLAY_CHILDREN = this.CHILDREN.filter(child => !child.archived)
            }

            return DISPLAY_CHILDREN.sort(function(a, b) {
              if (a.lastName.toLocaleLowerCase() < b.lastName.toLocaleLowerCase()) return -1;
              if (a.lastName.toLocaleLowerCase() > b.lastName.toLocaleLowerCase()) return 1;
              return 0;
            });
          },
            ...mapGetters(['CHILDREN']),
        },
        methods: {
            ...mapActions(['GET_CHILDREN_FROM_API']),
        },
        mounted() {
            this.GET_CHILDREN_FROM_API();
        }
    }
</script>

<style>

</style>