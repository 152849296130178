<template>
  <div class='v-report-payment row'>
    <div class="col-12">
      <h1>Выплаты</h1>
      <table class="table">
        <thead>
        <tr>
          <th>Сотрудник</th>
          <th v-for="period in this.report.periods"
          :key="period">
            {{ period }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="employee_id in this.report.employee_ids"
            :key="employee_id">

          <th scope="row">
            {{ this.EMPLOYEES[employee_id].lastName }}
            {{ this.EMPLOYEES[employee_id].firstName }}</th>
          <td v-for="period in this.report.periods"
              :key="period">
            <p v-if="this.report.amounts[employee_id] && this.report.amounts[employee_id][period]">
            {{ this.report.amounts[employee_id][period] / 100 }}
            </p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>


import axios from "axios";

export default {
  name: "v-report-payment",
  props: {},
  data() {
    return {
      TRANSACTIONS: {},
      EMPLOYEES: [],
      report: {
        employee_ids: [],
        periods: [],
        amounts: [],
      }
    }
  },
  computed: {},
  methods: {
    async GET_TRANSACTIONS() {
      let response = await axios.get('/transaction/report/salary')
      this.report = await response.data

    },
    async GET_EMPLOYEES() {
      const response = await axios.get('/employee/')
      response.data.forEach(element => {
        this.EMPLOYEES[element.id] = element
      });
    },
  },
  mounted() {
    this.GET_EMPLOYEES();
    this.GET_TRANSACTIONS();
  }
}

</script>

<style>

</style>