<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>SimpleCRM</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="list-group mt-3">
          <router-link class="list-group-item list-group-item-action" active-class="active" :to="{name: 'main_page'}">Главная</router-link>
          <router-link class="list-group-item list-group-item-action" active-class="active" :to="{name: 'tasks_list'}">Задачи</router-link>
          <router-link class="list-group-item list-group-item-action" active-class="active" :to="{name: 'children_list'}">Дети</router-link>
          <router-link class="list-group-item list-group-item-action" active-class="active" :to="{name: 'parents_list'}">Родители</router-link>
          <router-link class="list-group-item list-group-item-action" active-class="active" :to="{name: 'employees_list'}">Сотрудники</router-link>
          <router-link class="list-group-item list-group-item-action" active-class="active" :to="{name: 'leads_list'}">Лиды</router-link>
          <router-link class="list-group-item list-group-item-action" active-class="active" :to="{name: 'transactions_list'}">Финансы</router-link>
          <router-link class="list-group-item list-group-item-action" active-class="active" :to="{name: 'settings'}">Настройки</router-link>
          <router-link class="list-group-item list-group-item-action" active-class="active" :to="{name: 'report_payment'}">Отчет по оплатам</router-link>
          <router-link class="list-group-item list-group-item-action" active-class="active" :to="{name: 'report_salary'}">Отчет по зарплатам</router-link>
        </div>
      </div>
      <div class="col-9">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>

    export default {
        name: "v-main-wrapper",
        props: {},

        data() {
            return {
                title: "Simple CRM",
            }
        },
        computed: {},
        mounted() {
            console.log('Created!')
        },
    }

</script>

<style>

</style>