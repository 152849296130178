<template>
  <div class='v-child-details'>
    <div class="form-group row">
      <div class="col-3">
        <div class="form-group row">
          <img :src="child.photo ? '/upload/'+child.photo : '/images/no_photo.png'"
               style="width:100%; height:100%"
               :key="child.photo"
               alt="Child photo" >
          <div v-if="child.id !== -1">
            <input type="file" @change="uploadFile" ref="file" accept=".jpeg,.jpg,.png">
            <button @click="submitFile">Upload!</button>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="form-group row">
          <label class="col-form-label col-3 form-control-lg" for="firstName">Имя</label>
          <div class="form-group col-8">
            <input type="text" class="form-control" id="firstName" placeholder="Имя" v-model="child.firstName">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-3 form-control-lg" for="lastName">Фамилия</label>
          <div class="form-group col-8">
            <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="child.lastName">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-3 form-control-lg" for="fatherName">Отчество</label>
          <div class="form-group col-8">
            <input type="text" class="form-control" id="fatherName" placeholder="Отчество" v-model="child.fatherName">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-3 form-control-lg" for="dateOfBirth">Дата рождения</label>
          <div class="form-group col-8">
            <Datepicker
                :enable-time-picker="false"
                format='dd.MM.yyyy'
                id="dateOfBirth"
                @update:model-value="DATE_SELECTED"
                v-model="date"
                class="form-control"/>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="description">Примечание</label>
      <div class="form-group col-8">
        <textarea v-model="child.note" class="form-control" id="description" rows="3"></textarea>
      </div>
    </div>
    <div class="form-check form-control-lg">
      <input class="form-check-input" type="checkbox" v-model="child.archived" id="archived">
      <label class="form-check-label" for="archived">
        В архиве
      </label>
    </div>
    <button type="button" class="btn btn-primary" @click="UPDATE_CHILD">Сохранить</button>

    <div v-if="child.parents && child.parents.length > 0">
      <h3>Родители</h3>
      <table class="table">
        <thead>
        <tr>
          <th>id</th>
          <th>ФИО</th>
          <th>Телефон</th>
          <th>Удалить</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="parent in child.parents"
            :key="parent.id"
        >
          <th scope="row">
            <router-link :to="{name: 'parent', params: {id: parent.id}}">{{ parent.id }}</router-link>
          </th>
          <td>{{ parent.lastName }} {{ parent.firstName }}</td>
          <td>{{ parent.phone }}</td>
          <td>
            <button type="button" class="btn btn-primary" @click="this.REMOVE_PARENT(parent.id)">Убрать</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <router-link v-if="child.id !== -1" :to="{name: 'addParentToChild', params: {id: child.id}}">
      <button type="button" class="btn btn-primary">Выбрать родителей</button>
    </router-link>
  </div>
</template>

<script>
import axios from 'axios'
import router from "@/router/router";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment";

import {mapActions} from 'vuex'


export default {
  name: "v-child-details",
  props: {},
  data() {
    return {
      date: moment("01.01.2020", "DD.MM.YYYY").toDate(),
      child: {
        id: -1,
        firstName: "",
        lastName: "",
        fatherName: "",
        dateOfBirth: "01.01.2020",
        note: "",
        photo: null,
        archived: false,
      }
    }
  },
  components: {
    Datepicker,
  },
  computed: {},
  methods: {
    async GET_CHILD(id) {
      const response = await axios.get('/child/' + id)
      this.child = response.data;
      this.date = moment(this.child.dateOfBirth, "DD.MM.YYYY").toDate();
    },
    async REMOVE_PARENT(parent_id) {
      const response = await axios.post('/child/' + this.child.id + '/removeParent/' + parent_id, "")
      this.child = response.data;
    },
    UPDATE_CHILD() {
      axios.post('/child/', this.child)
      this.GET_CHILDREN_FROM_API()
      router.push("/children")
    },
    DATE_SELECTED() {
      this.$nextTick(() => {
        this.child.dateOfBirth = moment(this.date).format("DD.MM.YYYY");
      })

    },
    ...mapActions(['GET_CHILDREN_FROM_API']),
    uploadFile() {
      this.Images = this.$refs.file.files[0];
    },
    submitFile() {
      const formData = new FormData();
      formData.append('file', this.Images);

      axios.post('/child/' + this.child.id + '/uploadPhoto', formData).then((response) => {
        this.child = response.data;
      });
    }
  },
  mounted() {
    let id = this.$route.params.id
    if (id) {
      this.GET_CHILD(this.$route.params.id)
    }
  },
}

</script>

<style>
.v-child-details {

}
</style>