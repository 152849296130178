<template>
    <div class='v-employee-details'>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="firstName">Имя</label>
        <div class="form-group col-8">
          <input type="text" class="form-control" id="firstName" placeholder="Имя" v-model="employee.firstName">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="lastName">Фамилия</label>
        <div class="form-group col-8">
          <input type="text" class="form-control" id="lastName" placeholder="Фамилия" v-model="employee.lastName">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="fatherName">Отчество</label>
        <div class="form-group col-8">
          <input type="text" class="form-control" id="fatherName" placeholder="Отчество" v-model="employee.fatherName">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="dateOfBirth">Дата рождения</label>
        <div class="form-group col-8">
          <Datepicker
              :enable-time-picker="false"
              format='dd.MM.yyyy'
              id="dateOfBirth"
              @update:model-value="DATE_SELECTED"
              v-model="date"
              class="form-control"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="passport">Паспорт</label>
        <div class="form-group col-8">
          <input type="text" class="form-control" id="passport" placeholder="Паспорт" v-model="employee.passport">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="phone">Телефон</label>
        <div class="form-group col-8">
          <input type="text" class="form-control" id="phone" placeholder="Телефон" v-model="employee.phone">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="email">E-mail</label>
        <div class="form-group col-8">
          <input type="text" class="form-control" id="email" placeholder="email" v-model="employee.email">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-3 form-control-lg" for="description">Примечание</label>
        <div class="form-group col-8">
          <textarea v-model="employee.note" class="form-control" id="description" rows="3"></textarea>
        </div>
      </div>
      <div class="form-check form-control-lg">
        <input class="form-check-input" type="checkbox" v-model="employee.archived" id="archived">
        <label class="form-check-label" for="archived">
          В архиве
        </label>
      </div>
      <button type="button" class="btn btn-primary" @click="UPDATE_EMPLOYEE">Сохранить</button>

      <div>
        <h3>Ставки</h3>
        <div v-for="rate in rates"
             :key="rate.id">
          <h5 v-if="rate.rateType==='MONTHLY'">
            {{ rate.rate }} Ежемесячная
            <button type="button" class="btn btn-outline-danger" @click="DELETE_RATE(rate.id)">-</button>
          </h5>
          <h5 v-else>
            {{ rate.rate }} За занятие
            <button type="button" class="btn btn-outline-danger" @click="DELETE_RATE(rate.id)">-</button>
          </h5>
        </div>

        <div>
          Добавить ставку:
        </div>
        <label class="col-form-label col-3 form-control-lg" for="rate">Сумма</label>
        <div class="form-group col-3">
          <input type="text" class="form-control" id="email" placeholder="rate" v-model="newRate.rate">
        </div>
        <div class="form-group col-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="rateType" id="flexRadioDefault1"
                   v-model="newRate.rateType"
                   value="MONTHLY"
                   checked>
            <label class="form-check-label" for="flexRadioDefault1">
              Ежемесячная
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="rateType" id="flexRadioDefault2"
                   v-model="newRate.rateType"
                   value="SINGLE">
            <label class="form-check-label" for="flexRadioDefault2">
              За занятие
            </label>
          </div>
        </div>
        <button type="button" class="btn-sm btn-primary" @click="ADD_RATE">Добавить</button>
      </div>
    </div>

</template>

<script>
    import axios from 'axios'
    import router from "@/router/router";
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import moment from "moment";

    export default {
        name: "v-employee-details",
        props: {
        },
        data() {
          return {
            date: moment( "01.01.2000", "DD.MM.YYYY").toDate(),
            employee: {
              firstName: "",
              lastName: "",
              fatherName: "",
              dateOfBirth: "01.01.2000",
              passport: "",
              phone: "",
              email: "",
              note: "",
              archived: false,
            },
            rates: [],
            newRate: {
              employee:{
                id: -1,
              },
              rate:      "",
              rateType:  "MONTHLY",
              startDate: "01.01.2023",
              endDate:   "31.12.2023",
            }
          }
        },
      components: {
        Datepicker,
      },
        computed: {
        },
      methods: {
        async GET_EMPLOYEE(id) {
          const response = await axios.get('/employee/'+id)
          this.employee = response.data;
          this.date = moment(this.employee.dateOfBirth, "DD.MM.YYYY").toDate();
        },
        async GET_RATES(id) {
          const response = await axios.get('/rate/employee/'+id)
          this.rates = response.data;
        },
        async UPDATE_EMPLOYEE() {
          const response = await axios.post('/employee/', this.employee)
          this.employee = response.data;
          router.push("/employees")
        },
        async ADD_RATE() {
          this.newRate['employee']['id'] = this.employee.id
          await axios.post('/rate/', this.newRate)
          await this.GET_RATES(this.employee.id)
          this.newRate.rate=""
        },
        async DELETE_RATE(id) {
          if (window.confirm("Удалить ставку?")) {
            await axios.delete('/rate/'+id)
            await this.GET_RATES(this.employee.id)
          }
        },
        DATE_SELECTED() {
          this.$nextTick(() => {
            this.employee.dateOfBirth = moment(this.date).format("DD.MM.YYYY");
          })
        }
      },
      mounted() {
          let id = this.$route.params.id
          if ( id ) {
            this.GET_EMPLOYEE(id)
            this.GET_RATES(id)
          }
      }
    }

</script>

<style>
    .v-employee-details{

    }
</style>