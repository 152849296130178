<template>
  <div class='v-employee-details'>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="wallet">Счёт</label>
      <div class="form-group col-8">
        <select class="form-select" aria-label="Default select example" v-model="transaction.wallet.id">
          <option selected disabled value="">Выберите тип:</option>
          <option v-for="wallet in WALLETS"
                  :key="wallet.id"
                  :value="wallet.id">{{ wallet.name }} ({{ wallet.amount / 100 }})</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="amount">Сумма</label>
      <div class="form-group col-8">
        <input type="text" class="form-control" id="amount" placeholder="0.00" v-model="transaction.amount">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="fatherName">Тип операции</label>
      <div class="form-group col-8">
        <select class="form-select" aria-label="Default select example" v-model="transaction.transactionType">
          <option selected value="">Выберите тип:</option>
          <option value="DEBIT">Приход</option>
          <option value="CREDIT">Расход</option>
          <option value="SALARY">Зар.плата</option>
          <option value="PAYMENT">Оплата</option>
          <option value="CORRECT">Корректировка</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="fatherName">Категория</label>
      <div class="form-group col-8">
        <select class="form-select" aria-label="Default select example" v-model="transaction.transactionCategory.id">
          <option selected value="">Выберите категорию:</option>
          <option v-for="category in FILTERED_CATEGORIES"
                  :key="category.id"
                  :value="category.id">{{ category.name }}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="dateTime">Дата операции</label>
      <div class="form-group col-8">
        <Datepicker
            :enable-time-picker="true"
            format='dd.MM.yyyy HH.mm'
            id="dateTime"
            @update:model-value="DATE_SELECTED"
            v-model="date"
            class="form-control"
            disabled
        />
      </div>
    </div>
    <div v-if="transaction.transactionType === 'SALARY'" class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="fatherName">Выберите сотрудника</label>
      <div class="form-group col-8">
        <select class="form-select" aria-label="Default select example" v-model="transaction.arguments.employee">
          <option selected disabled>Выберите сотрудника</option>
          <option v-for="employee in EMPLOYEES"
                  :key="employee.id"
                  :value="employee.id">{{ employee.lastName }} {{ employee.firstName }}</option>
        </select>
      </div>
      <label class="col-form-label col-3 form-control-lg">Период оплаты</label>
      <div class="form-group col-5">
        <select class="form-select" aria-label="Default select example" v-model="transaction.arguments.payment_month">
          <option selected disabled>Выберите месяц</option>
          <option v-for="month in values_months"
                  :key="month.index"
                  :value="month.index">{{ month.title }} </option>
        </select>
      </div>
      <div class="form-group col-3">
        <input type="text" class="form-control" id="amount" placeholder="Год" v-model="transaction.arguments.payment_year">
      </div>
    </div>
    <div v-if="transaction.transactionType === 'PAYMENT'" class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="fatherName">Выберите ребёнка</label>
      <div class="form-group col-8">
        <select class="form-select" aria-label="Default select example" v-model="transaction.arguments.child">
          <option selected disabled>Выберите ребёнка</option>
          <option v-for="child in CHILDREN"
                  :key="child.id"
                  :value="child.id">{{ child.lastName }} {{ child.firstName }}</option>
        </select>
      </div>
      <label class="col-form-label col-3 form-control-lg">Период оплаты</label>
      <div class="form-group col-5">
        <select class="form-select" aria-label="Default select example" v-model="transaction.arguments.payment_month">
          <option selected disabled>Выберите месяц</option>
          <option v-for="month in values_months"
                  :key="month.index"
                  :value="month.index">{{ month.title }} </option>
        </select>
      </div>
      <div class="form-group col-3">
        <input type="text" class="form-control" id="amount" placeholder="Год" v-model="transaction.arguments.payment_year">
      </div>

    </div>
    <div class="form-group row">
      <label class="col-form-label col-3 form-control-lg" for="note">Примечание</label>
      <div class="form-group col-8">
        <textarea v-model="transaction.note" class="form-control" id="note" rows="3"></textarea>
      </div>
    </div>
    <button type="button" class="btn btn-primary" @click="UPDATE_TRANSACTION">Сохранить</button>
    <button v-if="transaction.id" type="button" class="btn btn-outline-danger" @click="DELETE_TRANSACTION(transaction.id)">Удалить</button>

  </div>

</template>

<script>
import axios from 'axios'
import router from "@/router/router";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment";
import values from "@/service/values"

export default {
  name: "v-transaction-details",
  props: {},
  data() {
    return {
      date: moment().toDate(),
      transaction: {
        wallet: {id: 0, name: ""},
        amount: 0,
        transactionType: "",
        transactionCategory: {id: 0, name: ""},
        dateTime: this.date,
        arguments: {payment_month: 0, payment_year: 2023},
        note: "",
      },
      WALLETS: [],
      EMPLOYEES: [],
      CHILDREN: [],
      TRANSACTION_CATEGORIES: [],
      transactionTypes: {
        DEBIT: "Приход",
        CREDIT: "Расход",
        SALARY: "Зар.плата",
        PAYMENT: "Оплата",
        CORRECT: "Корректировка",
      },
    }
  },
  components: {
    Datepicker,
  },
  computed: {
    FILTERED_CATEGORIES: function () {
      return this.TRANSACTION_CATEGORIES.filter(trans_cat => trans_cat.transactionType === this.transaction.transactionType)
    },
    values_months() {
      return values.months
    }
  },
  methods: {
    async GET_WALLETS() {
      const response = await axios.get('/wallet/')
      this.WALLETS = response.data;
    },
    async GET_EMPLOYEES() {
      const response = await axios.get('/employee/')
      this.EMPLOYEES = response.data;
    },
    async GET_CHILDREN() {
      const response = await axios.get('/child/')
      this.CHILDREN = response.data;
    },
    async GET_TRANSACTION_CATEGORIES() {
      const response = await axios.get('/transaction_category/')
      this.TRANSACTION_CATEGORIES = response.data;
    },
    async GET_TRANSACTION( id ) {
      const response = await axios.get('/transaction/' + id)
      this.transaction = response.data
      this.transaction.amount /= 100
    },
    async UPDATE_TRANSACTION() {
      this.transaction.amount *= 100
      await axios.post('/transaction/', this.transaction)
      await router.push("/transactions")
    },
    async DELETE_TRANSACTION(id) {
        if (window.confirm("Удалить операцию?")) {
            await axios.delete('/transaction/'+id)
            await router.push("/transactions")
        }
    },
    DATE_SELECTED() {
      this.$nextTick(() => {
        this.transaction.dateTime = moment(this.date).format("DD.MM.YYYY");
      })
    }
  },
  mounted() {
    this.GET_WALLETS();
    this.GET_EMPLOYEES();
    this.GET_CHILDREN();
    this.GET_TRANSACTION_CATEGORIES();
    let id = this.$route.params.id
    if (id) {
      this.GET_TRANSACTION(id)
    } else {
      this.transaction.arguments.payment_year = 2023
    }
  }
}

</script>

<style>
.v-employee-details {

}
</style>